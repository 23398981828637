<template>
  <div class="Notice">
    <div class="bigImg">
    </div>
    <tit-vue :titleContent="changeTitle.titleTxt1"></tit-vue>
    <img class="loading" v-if="loading == true" src="../../static/common/loading.png" alt="">
    <ul class="noticeBox">
      <li @mouseover="mouseoverli" 
          @mouseleave="mouseleaveli"
          @click="clickNotice(item.id)"
          v-for="item in noticeData" 
          :key="item.id"
          :class="control ? 'mouse' : ''"
          style="animation-duration: 500ms">
        <div>
          <p class="circle">
            <span></span>
            <span>{{item.title}}</span>
          </p>
          <p class="nTitle">
            <span>{{item.dt}}</span>
            <img src="../../static/aboutus/right.png" alt="">
          </p>
        </div>
      </li>
    </ul>
    <button @click="moreNotice">
      <span>更多</span>
      <img src="../../static/market/more.png" alt="">
    </button>
  </div>
</template>

<script>
  import TitVue from './index/tit.vue';
  import {noticeInit} from '../../api/info';
  export default {
    name: 'Notice',
    components: {
      TitVue,
    },
    data() {
      return {
        changeTitle: {
          titleTxt1: ['平台公告', false],
        },
        // 数据
        noticeData: [],
        control:false,
        // 接口参数
        attr:{
          pageIndex:0,
          pageSize:5,
        },
        loading:true,
      }
    },
    created() {
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
      this.init(this.attr,0);
    },
    methods:{
      // 初始化数据
      init(attr,key){
        noticeInit(attr).then(res=>{
          if(res.ok == 1){
            this.loading = false;
            if(key == 1){
              this.noticeData = [...this.noticeData,...res.data.List];
            }else{
              this.noticeData = res.data.List;
            }
          }
        }).catch(err=>{
          console.log(err);
        })
      },
      // 鼠标移入事件
      mouseoverli(event){
        let _el = event.currentTarget;
        if(_el.getAttribute('class') === 'mouse'){
          _el.setAttribute('class', '');
        } else {
          _el.setAttribute('class', 'mouse');	
        }
      },
      // 鼠标移出事件
      mouseleaveli(event){
        let _el = event.currentTarget;
        _el.setAttribute('class', '');
      },
      // 获取更多资讯
      moreNotice(){
        if(this.attr.pageSize == 5){
          this.attr.pageIndex = 1;
          this.attr.pageSize = 10;
          this.init(this.attr,0);
        }else{
          this.attr.pageIndex++;
          this.init(this.attr,1);
        }
      },
      // 点击跳转平台公告详情页 
      clickNotice(attr){
        let routeData = this.$router.resolve({ 
          name: "NoticeDetails",
            query: { 
              id:attr
            }
        });
        window.open(routeData.href, '_blank'); 
      }
    }
  }
</script>

<style lang="less" scoped>
  .Notice {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loading{
      margin: 0 auto 30px;
      width: 50px;
    }
    .bigImg {
      width: 100%;
      height: 600px;
      background: url('../../static/aboutus/banner.jpg') no-repeat;
      background-size: cover;
    }

    .noticeBox {
      width: 1416px;
      margin: 0 auto;
      li{
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 32px;
        box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
        margin-bottom: 30px;
        cursor: pointer;
        div{
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .circle{
            display: flex;
            justify-content: center;
            align-items: center;
            span:nth-child(1){
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: #333333;
              border-radius: 50%;
              margin-right: 15px;
            }
          }
          .nTitle{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 36px;
              margin-left: 15px;
              opacity: 0;
            }
          }
        }
      }
    }
    .mouse{
      // animation-duration: 500ms;
      div{
        border-bottom: 1px solid #db3d4c;
        transition: all 0.3s;
        .circle{
          span:nth-child(1){
            background-color: #db3d4c !important;
            transition: all 0.3s;
          }
        }
        .nTitle{
          img{
            width: 36px !important;
            opacity: 1 !important;
            transition: all 0.3s;
          }
        }
        span{
          color: #db3d4c;
          transition: all 0.3s;
        }
      }
    }
    button{
      width: 195px;
      height: 60px;
      border-radius: 5px;
      background-color: #db3d4c;
      border: none;
      margin: 32px auto 110px; 
      cursor: pointer;
      span{
        color: #ffffff;
        margin-right: 5px;
      }
      img{
        width: 10px;
        height: auto;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .Notice{
      width: 100%;
      margin: 0 auto;
      .bigImg{
        width: 100%;
        height: 450px;
        background: url('../../static/index/wapImg/noticeBanner.jpg') no-repeat;
        background-size: cover;
      }
      .noticeBox{
        width: 100%;
        box-sizing: border-box;
        padding: 0 5px;
        li{
          box-sizing: border-box;
          height: 70px;
          padding: 0 10px 0 10px;
          margin-bottom: 10px;
          div{
            .circle{
              span:nth-child(1){
                width: 4px;
                height: 4px;
              }
              span{
                font-size: 14px;
              }
            }
            .nTitle{
              span{
                font-size: 12px;
                width: 70px;
                text-align: center;
              }
            }
          }
        }
      }
      .mouse{
        div{
          border-bottom: none !important;
          transition:none;
          .circle{
            span:nth-child(1){
              background-color: #333333 !important;
              transition: none;
            }
          }
          .nTitle{
            img{
              display: none !important;
              transition: none;
            }
          }
          span{
            color: #333333;
            transition: none;
          }
        }
      }
      button{
        width: 140px;
        height: 40px;
        border-radius: 5px;
        background-color: #db3d4c;
        border: none;
        margin: 22px auto 50px; 
        cursor: pointer;
        span{
          font-size: 14px;
          color: #ffffff;
          margin-right: 5px;
        }
        img{
          width: 10px;
          height: auto;
        }
      }
    }
  }
</style>